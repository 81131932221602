<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat :loading="loadingstatus"
        loading-text="Loading... Please wait">
        <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn medium color="primary" @click="btnsearch" class="mr-1">
            <v-icon left>mdi-database-refresh</v-icon>LOAD DATA</v-btn
          >

<export-excel
            v-if="desserts.length > 0"
            :data="desserts"
            type="button"
          >
            <v-btn color="success"
              ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
            >
          </export-excel>
        </v-card-actions>
      <v-row>
      
          <!-- <v-select
            label="Select Company"
            v-model="SelCompany"
            :items="Company"
            item-text="company_name"
            item-value="company_id"
            dense
            outlined
            hide-details
          ></v-select> -->
        

        <!-- <v-col cols="12" md="3">
          <v-select
            label="Select Status"
            v-model="Selstatus"
            :items="Status"
            item-text="status_named"
            item-value="status_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col> -->

        <v-col cols="12" md="2">
         
        </v-col>

        <v-col cols="12" md="2">
          
        </v-col>
      </v-row>
    </v-card>
    <v-row>
        <v-col cols="12" md="12" sm="12">
            <v-card  flat v-if="desserts.length > 0">
      <v-data-table
        v-model="TDselected"
       :headers="headers"
        :items="desserts"
        :search="search"
      
         hide-default-header
      
      >
        <!-- @click="BtnApprove(index)" -->
 <template v-slot:header="{ props }">
        <th class="tdalign sticky-header blue lighten-5" v-for="head in props.headers" v-bind:key="head.card_name">{{ head.text.toUpperCase() }}
          </th>
      </template>

     <template v-slot:item="{ item }">
       
       <tr class="tdalign">
         <td > {{item.card_name}}</td>
         <td>{{item.card_capacity}}</td>
          <td>{{item.printed}}</td>
           <td>{{item.reprinted}}</td>
            <td>{{item.balance}}</td>
       </tr>
     </template>

         <template slot="body.append">
          <tr class="tdalign sticky-header blue lighten-5">
            <td  colspan="1">Total</td>
            
            <td>{{ sumField("card_capacity") }}</td>
            <td >{{ sumField("printed") }}</td>
            <td >{{ sumField("reprinted") }}</td>
            <td >{{ sumField("balance") }}</td>
           
          </tr>
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>
        </v-col>
    </v-row>
    

    <v-snackbar v-model="snackbar" shaped top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
//import ViewApplication from "../../components/ViewApplication.vue";
export default {
  data() {
    return {
      TDselected: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: true,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

      Selstatus: "8",

      SelstatusForUpade: "",

      SelReasonForUpdate: "",
      ResonForUpdate: [],

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],
      strcheckatatus: "",

      SelCompany: "",
      Company: [],

      //companytype: [],
      companytype_id: [],
      ReportFor:'',

      headers: [
        {
          text: "Card",
          align: "left",
          value: "card_name",
          class: "blue lighten-5 sticky-header",
         
        },

        {
          text: "Capacity",
          value: "card_capacity",
          align: "left",
          class: "blue lighten-5 sticky-header",
        },

        {
          text: "Printed",
          value: "printed",
          align: "left",
          class: "blue lighten-5 sticky-header",
        },
         {
          text: "Re-Printed",
          value: "reprinted",
          align: "left",
          class: "blue lighten-5 sticky-header",
        },
         {
          text: "Balance",
          value: "balance",
          align: "left",
          class: "blue lighten-5 sticky-header",
        },

      ],
      desserts: [],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
    sessionStorage.display_event_name = "IPL T20 2021";
    sessionStorage.display_venue_name = "Chinaswamy Stadium";

    this.$store.commit("setPageTitle", "Card Inventory Report");
    this.$store.commit("setPageTitleIcon", "mdi-file-chart");
    //this.$store.commit("setEventName", sessionStorage.display_event_name);
   // this.$store.commit("setVenueName", sessionStorage.display_venue_name);

    // this.bindStatus();
    this.bindCartype(this.$store.state.eventInfo.EventId);
    this.bindCompany(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
    //  this.bindReson("1");
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

  

    chkall: function () {
     
    },

     sumField(key) {
      // sum data in give key (property)
      return this.desserts.reduce((a, b) => a + (b[key] || 0), 0);
    },

   
    btnsearch: function () {
     
      this.Reload();
     
    },

    bindCompany: async function (EventID, VenueID) {
      await this.$axios
        .get("vendor/GetByEventId/" + EventID + "/" + VenueID)
        .then((res) => {
          if (res.data.result.length > 0) {
            this.Company = res.data.result;
          }
        });
    },

    bindCartype: async function (event_id) {
      await this.$axios
        .get("Cards/GetByEventId/" + event_id)
        .then((res) => {
          this.CardTypeForUpdate = res.data.result;
        });
    },

    bindStatus: async function () {
      await this.$axios
        .get("Status")
        .then((res) => {
          this.Status = res.data.result;
          this.StatusForUpdate = res.data.result;
        });
    },

    Reload: async function () {
      this.loadingstatus = true;
      if(this.$store.state.eventInfo.venuetype=="Central"){
        this.ReportFor="Central";
      }
      else{
        this.ReportFor="Local";
      }
      await this.$axios
        .get(
          "CardInventory/" + this.$store.state.eventInfo.EventId
        )
        .then((res) => {
            console.log(res.data.result);
          this.desserts = res.data.result;

          this.loadingstatus = false;
        })
        .catch()
        .finally();
    },
  },
};
</script>

<style  scoped>
.tdalign {
  text-align: left;
  padding:1rem;
  font-size: 0.8em;
}
.v-data-table /deep/ .sticky-header {
  position: sticky; top: 0px; z-index: 5
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>>

